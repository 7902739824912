<template>
  <section class="dtc-main-section py-4">
    <div class="dtc-grid-3">
      <h4 class="title mb-3" style="font-weight: bold;font-family: DFKai-sb;">
        輔導問卷調查統計
      </h4>
      <b-select
        :options="years"
        v-model="year"
        @change="getData"
        style="font-size:16px !important"
        hidden
      ></b-select>
      <b-button
        size="sm"
        variant="success"
        class="mr-2 download-btn d-print-none"
        @click="downloadFile"
      >
        <i class="fas fa-file-download"></i>下載資料
      </b-button>
    </div>
    <!-- <header class="d-flex header-dark" style="text-align:center;">
      <div class="content col-width-1">題目</div>
      <div class="content col-width-2 border-left">選項</div>
      <div class="content col-width-3 border-left">討論</div>
    </header>
    <main
      v-for="(item, idx) in items"
      :key="idx"
      class="d-flex align-items-stretch border-left border-right border-bottom"
    >
      <div class="col-width-1 d-flex p-1">
        <div v-text="`${idx + 1}.`" class="pr-2" />
        <div></div>
        <div v-text="item.question" />
      </div>
      <div
        class="col-width-2 d-grid border-left"
        :class="`grid-col-${item.options.length}`"
      >
        <div
          v-for="(option, oIdx) in item.options"
          :key="`${idx}_${oIdx}_option`"
          class="h-100 p-1"
          :class="{ 'border-left': oIdx > 0 }"
        >
          <div v-text="option.text" />
          <div v-text="`(${option.percentage}%)`" />
        </div>
      </div>
      <div class="col-width-3 border-left p-1" v-text="item.discuss" />
    </main> -->

    <div v-for="(citem, ids) in items" :key="`keyone_${ids}`">
      <h4
        class="title mb-3 mt-5"
        style="font-weight: bold;font-family: DFKai-sb;"
        :style="ids > 0 ? 'page-break-before: always;' : ''"
      >
        {{ citem.header }}
      </h4>
      <header class="d-flex header-dark" style="text-align:center;">
        <div class="content col-width-1">題目</div>
        <div class="content col-width-2 border-left">選項</div>
        <div class="content col-width-3 border-left">討論</div>
      </header>
      <main
        v-for="(item, idx) in citem.category"
        :key="idx"
        class="d-flex align-items-stretch border-left border-right border-bottom"
      >
        <div class="col-width-1 d-flex p-1">
          <!-- <div v-text="`${idx + 1}.`" class="pr-2" /> -->
          <div></div>
          <div v-text="item.question" />
        </div>
        <div
          style="min-height:10px;"
          class="out col-width-2 d-grid border-left"
          :class="`grid-col-${item.options.length}`"
        >
          <div
            style="word-break:break-all"
            v-for="(option, oIdx) in item.options"
            :key="`${idx}_${oIdx}_option`"
            class="in h-100 p-1"
            :class="{ 'border-left': oIdx > 0 }"
          >
            <div v-text="option.text" />
            <div v-text="`(${option.percentage}%)`" />
          </div>
        </div>
        <div class="col-width-3 border-left p-1" v-text="item.discuss" />
      </main>
    </div>
  </section>
</template>

<script>
import queryString from "query-string";
import { store } from "@/store/global.js";
const year = +new Date().getFullYear() - 1911;
const years = new Array(3).fill().map((s, i) => year - i);
const headers = [
  "表一、受訪者人口學變項與受訪者目前狀態",
  "表二、在學中受訪者的意見",
  "表三、專業訓練中受訪者的意見",
  "表四、服務中受訪者的意見",
  "表五、服務期滿留任受訪者的意見",
  "表六、服務期滿離任受訪者的意見",
];

export default {
  data() {
    return {
      items: [],
      c0: [],
      c1: [],
      c2: [],
      c3: [],
      c4: [],
      c5: [],
      headers,
      year,
      years,
    };
  },

  methods: {
    async downloadFile() {
      try {
        const {
          Message,
          Url,
        } = await axios.get(`LocalRecord/Report?year=${this.year}
        `);
        window.open(Url);
      } catch (e) {
        alert("" + e);
      }
    },
    async getData() {
      await window.axios
        .get(`LocalRecord/List?year=${this.year}`)
        .then((res) => {
          this.items = res.map((item) => ({
            category: item.Category,
            question: item.Question,
            discuss: "",
            options: item.AnswerNames.map((text, index) => ({
              text,
              percentage: item.AnswerRates[index]
                ? item.AnswerRates[index].toFixed(2)
                : 0,
            })),
          }));
        });
      let num = this.items.length - 1;
      num = this.items[num].category ? +this.items[num].category : 1;

      let obj = {};
      this.c0 = this.items.filter((s) => !s.category);
      obj.category = this.c0;

      this.items = this.items.map((s, i) => ({
        category: this.items.filter((k) => k.category == i + 1),
      }));
      this.items.unshift(obj);
      this.items = this.items.slice(0, num + 1);

      this.items = this.items.map((s, i) => ({
        header: this.headers[i],
        category: s.category,
      }));

      // this.c0 = this.items.filter((s) => !s.category);
      // this.c1 = this.items.filter((s) => s.category == "1");
      // this.c2 = this.items.filter((s) => s.category == "2");
      // this.c3 = this.items.filter((s) => s.category == "3");
      // this.c4 = this.items.filter((s) => s.category == "4");
      // this.c5 = this.items.filter((s) => s.category == "5");
      // console.log("this.c1", this.c1);
    },
  },
  mounted() {
    this.getData();
  },
  created() {},
  watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-main-section {
  max-width: 1200px;
  margin: 0 auto;
  .border-left {
    border-color: #000 !important;
  }
}

.d-grid {
  display: grid;
}
.col-width-1 {
  width: 16%;
}
.col-width-2 {
  width: 69%;
}
.col-width-3 {
  width: 15%;
}

@for $i from 1 through 50 {
  .grid-col-#{$i} {
    grid-template-columns: repeat($i, 1fr);
  }
}

.dtc-5-column,
.dtc-3-column,
.dtc-6-column {
  display: grid;
  grid-template-columns: 225px repeat(3, 170px) 225px;
  border: 1px solid black;
  border-top: none;

  > div {
    padding: 5px;
    padding-left: 10px;
    text-align: left;
    border-right: 1px solid black;
    font-size: 16px;
  }
  > div:last-child {
    border: 0px solid black;
    font-size: 16px;
  }
  .item {
    margin-left: 34px;
  }
  .item-content {
    text-align: center;
  }
}

.dtc-3-column {
  grid-template-columns: 225px 510px 225px;
  .content {
    text-align: center;
    font-weight: 900;
  }
}
.dtc-6-column {
  grid-template-columns: 225px repeat(4, 127.5px) 225px;
}

.number-div {
  display: grid;
  grid-template-columns: 10px 1fr;
  gap: 1rem;
}

.header-dark {
  border: 1px solid black;
  background: #646b74;
  color: #fff;
}
.dtc-grid-3 {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  // justify-content: center;
  grid-gap: 12px;
}

.download-btn {
  position: fixed;
  top: 35px;
  right: 191px;
  z-index: 99999;
  height: 40px;
}
@media print {
  @page {
    size: A4 portrait;
  }
  .dtc-main-section {
    width: 980px;
  }
  .header-dark {
    background: #fff;
    color: black;
  }
}
</style>
